import React, { useEffect, useRef, useState } from 'react'
import style from './style.module.css'
import { Tilt } from 'react-tilt';

function WebsiteBackground() {

    let numberOfBoxPerRow = document.documentElement.clientWidth > 1440 ? 15 : 10

    const [hoverBoxWidth, setHoverBoxWidth] = useState(0)
    const [hoverBoxRowsCount, setHoverBoxRowsCount] = useState(0)

    const hoverBox = useRef(null)

    useEffect(() => {
        const handleResize = () => {
            numberOfBoxPerRow = document.documentElement.clientWidth > 1440 ? 15 : 10;
            setHoverBoxWidth(document.documentElement.clientWidth / numberOfBoxPerRow)
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (hoverBoxWidth > 0) {
            let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            setHoverBoxRowsCount(Math.ceil(vh / hoverBoxWidth))
        }
    }, [hoverBoxWidth])

    useEffect(() => {
        const tiltElements = document.querySelectorAll(".onClickTiltAnimation");

        const handleTiltClick = (e) => {
            const element = e.currentTarget;
            element.classList.toggle("scaleElement");
            setTimeout(() => {
                element.classList.remove("scaleElement");
            }, 300);
        };

        tiltElements.forEach(element => {
            element.addEventListener("click", handleTiltClick);
        });

        return () => {
            tiltElements.forEach(element => {
                element.removeEventListener("click", handleTiltClick);
            });
        };
    }, [hoverBoxRowsCount]);  // Add hoverBoxRowsCount as dependency to ensure elements are updated

    const hoverBoxQuantity = Array.from({ length: numberOfBoxPerRow });
    const hoverBoxRowQuantity = Array.from({ length: hoverBoxRowsCount });

    const options = {
        reverse: true,  // reverse the tilt direction
        max: 20,     // max tilt rotation (degrees)
        perspective: 500,   // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1,    // 2 = 200%, 1.5 = 150%, etc..
        speed: 300,   // Speed of the enter/exit transition
        transition: true,   // Set a transition on enter/exit.
        axis: null,   // What axis should be disabled. Can be X or Y.
        reset: true,    // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

    return (
        <div className={style.backgroundMainDiv} >
            <div className={style.boxContainer}>
                {hoverBoxRowQuantity.map((_, rowIndex) => {
                    return (
                        <div key={rowIndex} className={style.hoverboxRow}>
                            {hoverBoxQuantity.map((_, i) => {
                                return (
                                    <Tilt key={i} options={options} id="tiltBox" className={`onClickTiltAnimation ${style.hoverBox}`} ref={hoverBox} style={{ height: hoverBoxWidth, width: hoverBoxWidth }}>
                                    </Tilt>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WebsiteBackground
