import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Home from './components/Home';
// import About from './components/About';
// import Services from './components/Services';
// import Contact from './components/Contact';
import './App.css'; // Importing the CSS file
import WebsiteBackground from './components/WebsiteBackground';

const App = () => {
  return (
      <WebsiteBackground/>
  );
};

export default App;
